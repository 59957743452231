const _ = {
  find: require('lodash/find'),
  pick: require('lodash/pick'),
};

import * as React from 'react';
import classnames from 'classnames';
import { Link, RouteComponentProps } from 'react-router-dom';
import gql from 'graphql-tag';
import { Mutation, MutationFn } from 'react-apollo';
import qs from 'qs';
import Helmet from 'react-helmet';
import { DataProxy } from 'apollo-cache';

import { routes } from 'inkp-routes/public';
import Input from 'inkp-components/dist/Components/Input';
import Button from 'inkp-components/dist/Components/Button';
import { validatePassword } from 'inkp-user-sdk/user';

import { StateContainer, Subscribe } from 'infra-frontend/helpers/apollo';

import { FieldError } from '../../interfaces';
import AccountLayout from '../../layout/account';
import { CURRENT_USER_FRAGMENT, updateCurrentUser, CurrentUserData } from '../../util/login';
import { findServiceError } from '../../util/apollo';

// GTM helpers
import GTM from '../../util/gtm';
import { GTMTypes } from '../../interfaces/GTM';

const RESET_MUTATION = gql`
  mutation ResetPassword($email: String!, $token: String!, $password: String!) {
    resetPassword(email: $email, token: $token, password: $password) {
      id
    }
  }
`;

const SIGNIN_MUTATION = gql`
  mutation SignIn($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      ...CurrentUser
    }
  }

  ${CURRENT_USER_FRAGMENT}
`;

interface State {
  fields: {
    password: string;
  };
  showPassword: boolean;
  errors: FieldError[];
  resetError: string;
}

function validateResetParams(fields: { password: string }) {
  const result = [];
  const pwValidation = validatePassword(fields.password);

  if (!pwValidation.valid) {
    result.push(Object.assign({ field: 'password' }, pwValidation));
  }

  return result;
}

class ResetState extends StateContainer {
  initialState = {
    fields: {
      password: '',
    },
    errors: [],
    resetError: '',
    showPassword: false,
  };

  shape = `
    {
      fields {
        password
      }
      errors {
        valid
        message
        field
      }
      resetError
      showPassword
    }
  `;

  // Defining event handler to update the state
  updateField = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const oldFieldError = _.find(this.state.errors, { field });
    const fields = Object.assign({}, this.state.fields, { [field]: event.target.value });
    const newFieldError = _.find(validateResetParams(fields), { field });

    let errors = this.state.errors;
    if (oldFieldError && (!newFieldError || newFieldError.message !== oldFieldError.message)) {
      errors = this.state.errors.map((error: { valid: boolean; message: string; field: string }) => {
        if (error.field !== field) return error;
        return Object.assign({}, error, { valid: true });
      });
    }

    this.setState({ fields, errors });
  };

  togglePassword = () => () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  setErrors = (errors: any) => {
    this.setState({ errors, resetError: '' });
  };

  setResetError = (error: string) => {
    this.setState({ resetError: error });
  };
}

export default class ResetPasswordPage extends React.Component<RouteComponentProps> {
  signInUpdate = () => (cache: DataProxy, { data }: { data: { signIn: CurrentUserData } }) => {
    updateCurrentUser(cache, data.signIn);
  };

  completeSignIn = () => () => {
    this.props.history.push(routes.app.base);
  };

  submit = (email: string, token: string, password: string, setErrors: any, reset: MutationFn) => (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    const validationResult = validateResetParams({ password });
    if (validationResult.length > 0) {
      return setErrors(validationResult);
    }

    reset({
      variables: { email, token, password },
    });
  };

  resetError = (setErrors: any, setResetError: any) => (error: any) => {
    const serviceError = findServiceError(error);
    if (serviceError && serviceError.extensions && serviceError.extensions.code === 'INVALID_RESET_TOKEN') {
      return setResetError('Your password reset request has expired');
    }
    return setResetError('Error encountered');
  };

  componentDidMount() {
    setTimeout(() => {
      GTM.push(GTMTypes.USER);
    }, 0);
  }

  render() {
    return (
      <Subscribe to={[ResetState]} namespace="ResetPassword">
        {({ state, updateField, setErrors, togglePassword, setResetError }) => (
          <Mutation
            mutation={SIGNIN_MUTATION}
            update={this.signInUpdate()}
            onCompleted={this.completeSignIn()}
            onError={this.completeSignIn()}
          >
            {(signIn: MutationFn) => {
              const passwordError = _.find(state.errors, { field: 'password' });
              const { email, token } = qs.parse((this.props.location.search || '').replace(/^\?/, ''));
              return (
                <Mutation
                  onCompleted={() => signIn({ variables: { email, password: state.fields.password } })}
                  mutation={RESET_MUTATION}
                  onError={this.resetError(setErrors, setResetError)}
                >
                  {(reset: MutationFn) => {
                    return (
                      <AccountLayout>
                        <Helmet>
                          <title>Inkpop - Reset Password</title>
                        </Helmet>
                        <div>
                          <div>
                            <h1 className="fs-xl color-coral fw-extra-bold">Reset Your Password</h1>
                          </div>
                          <div className="mt-1">
                            Almost there! Enter a new password for <b>{email}</b>.
                          </div>
                          <form
                            className="mt-2 w-full"
                            onSubmit={this.submit(email, token, state.fields.password, setErrors, reset)}
                          >
                            <Input
                              label="Password"
                              className="w-full mt-1p5"
                              inputType={state.showPassword ? 'text' : 'password'}
                              defaultValue={state.fields.password}
                              onChange={updateField('password')}
                              error={passwordError && !passwordError.valid}
                              helper={passwordError ? passwordError.message : state.resetError}
                              onRightIconClick={togglePassword()}
                              rightIcon={state.showPassword ? 'eye' : 'eye-off'}
                              helperColor={
                                (passwordError && !passwordError.valid) || state.resetError ? 'red' : 'green'
                              }
                            />
                            <Button buttonType="submit" className="w-full mt-1p5">
                              Reset Password
                            </Button>
                          </form>
                        </div>
                      </AccountLayout>
                    );
                  }}
                </Mutation>
              );
            }}
          </Mutation>
        )}
      </Subscribe>
    );
  }
}
